let leiding = {
    Catherine: {
        name: "Catherine Heyvaert",
        totem: "Goedlachs Konijn",
        email: "catherine@kramaai.be",
    },
    Andreas: {
        name: "Andreas Verhoeven",
        totem: "Oprechte Newfoundlander",
        email: "andreas@kramaai.be",
    },
    Merlijn: {
        name: "Merlijn Devroede",
        totem: "Eigenaardig Penseelaapje",
        email: "merlijn@kramaai.be",
    },
    Senne: {
        name: "Senne Lauwers",
        totem: "Schalkse Brulaap",
        email: "senne@kramaai.be",
    },
    Roxanne: {
        name: "Roxanne Hoornaert",
        totem: "Unieke Fazant",
        email: "roxanne@kramaai.be",
    },
    Delphine: {
        name: "Delphine Ilsbroux",
        totem: "Uitbundige Bezoar",
        email: "delphine@kramaai.be",
    },
    Tijs: {
        name: "Tijs De Jonge",
        totem: "Speelse Kauw",
        email: "tijs@kramaai.be",
    },
    Jari: {
        name: "Jari Heyvaert",
        totem: "Genietende steenbok",
        email: "jari@kramaai.be",
    },
    KobeG: {
        name: "Kobe Gysens",
        totem: "Ondeugende Ekster",
        email: "kobe@kramaai.be",
        number: "+32 474 67 70 75",
    },
    Fien: {
        name: "Fien De Jonge",
        totem: "Vlotte Wasbeer",
        email: "fien@kramaai.be",
    },
    Jules: {
        name: "Jules Eeman",
        totem: "Nonchalante Lepelaar",
        email: "jules@kramaai.be",
    },
    Jasper: {
        name: "Jasper Beeckman",
        totem: "Sympathieke Spitsvogel",
        email: "jasper@kramaai.be",
        number: "+32 468 29 27 35",
    },
    Ferre: {
        name: "Ferre Luppens",
        totem: "Ingetogen Kameel",
        email: "ferre@kramaai.be",
    },
    Romain: {
        name: "Romain Wouters",
        totem: "Respectvolle Schaarbek",
        email: "romain@kramaai.be",
    },
    Inne: {
        name: "Inne Van Lil",
        totem: "Enthousiaste Toekan",
        email: "inne@kramaai.be",
    },
    KobeB: {
        name: "Kobe Bogaert",
        totem: "Stoutmoedige Walrus",
        email: "kobeBogaert@kramaai.be",
    },
    Arthur: {
        name: "Arthur Van der Smissen",
        totem: "Onverschrokken Lori",
        email: "arthur@kramaai.be",
    },
    Wout: {
        name: "Wout Huysman",
        totem: "Respectvol Grijs Bokje",
        email: "wout@kramaai.be",
        number: "+32 470 09 30 61",
    },
    Robbe: {
        name: "Robbe Tops",
        totem: "Betrouwbare Dolfijn",
        email: "robbe@kramaai.be",
    },
    Joren: {
        name: "Joren Tops",
        totem: "Geestige Aap",
        email: "joren@kramaai.be",
    },
    VictorC: {
        name: "Victor Carle",
        totem: "Dromerige Bever",
        email: "victor.carle@kramaai.be",
    },
    VictorB: {
        name: "Victor Boterdaele",
        totem: "Zorgzame Golden Retriever",
        email: "victor@kramaai.be",
        number: "+32 478 67 03 78",
    },
    Remco: {
        name: "Remco Looverie",
        totem: "Goedhartige Vink",
        email: "remco@kramaai.be",
    },
    Amber: {
        name: "Amber Gysens",
        totem: "Stoutmoedige Arassari",
        email: "amber@kramaai.be",
    },
    Tibo: {
        name: "Tibo De Bondt",
        totem: "Beschermende Spitsvogel",
        email: "tibo@kramaai.be",
    },
    Toon: {
        name: "Toon Esselens",
        totem: "Nonchalante Pumba",
        email: "toon@kramaai.be",
    },
    Wiebe: {
        name: "Wiebe Luppens",
        totem: "Amusante Kolibrie",
        email: "wiebe@kramaai.be",
    },
    Elise: { name: "Elise Asselman", email: "elise@kramaai.be" },
    Laura: { name: "Laura De Smedt", totem: "Attente Anoa", email: "laura@kramaai.be" },
    Miel: { name: "Miel Meskens", totem: "Empathische Ooievaar", email: "miel@kramaai.be" },
    Luka: { name: "Luka Heyvaert", totem: "Zorgeloze Secretarisvogel", email: "luka@kramaai.be" },
    Leyla: { name: "Leyla Hoornaert", totem: "Tedere lepelaar", email: "leyla@kramaai.be" },
    Matthijs: { name: "Matthijs Huysman", totem: "Enthousiaste Parkiet", email: "matthijs@kramaai.be" },
    Klaas: { name: "Klaas De Jonge", totem: "Amusant Nijlpaard", email: "klaas@kramaai.be" },
    Luca: { name: "Luca Tastenoy", totem: "Onverschrokken Agame", email: "luca@kramaai.be" },
    Viktor: { name: "Viktor Van Gothem", email: "viktor@kramaai.be" },
    Emma: { name: "Emma Lammens", totem: "Goedgelovige Simia", email: "emma@kramaai.be" },
    Arno: { name: "Arno Engels", totem: "Behulpzame Berner Sennenhond", email: "arno@kramaai.be" },
    Lara: { name: "Lara Vebelen", totem: "Zorgzame sifaka", email: "lara@kramaai.be" },
};

let takken = {
    kapoenen: [
        leiding.Fien,
        leiding.Tijs,
        leiding.Emma,
        leiding.Miel,
        leiding.Laura,
        leiding.Amber,
        leiding.Robbe,
        leiding.Luka,
    ],
    welpen: [
        leiding.Joren,
        leiding.Romain,
        leiding.Luca,
        leiding.Tibo,
        leiding.Remco,
        leiding.Merlijn,
        leiding.Leyla,
        leiding.Arno,
    ],
    bevers: [leiding.KobeG, leiding.VictorB, leiding.Matthijs, leiding.Lara, leiding.Elise, leiding.Viktor, leiding.Klaas],
    jonggivers: [
        leiding.Ferre,
        leiding.Wout,
        leiding.Andreas,
        leiding.VictorC,
        leiding.Toon,
        leiding.Senne,
        leiding.Jules,
        leiding.Wiebe,
    ],
    givers: [leiding.Delphine, leiding.Catherine, leiding.Roxanne, leiding.KobeB, leiding.Jari],
    jins: [leiding.Jasper, leiding.Inne, leiding.Arthur],
    groep: [leiding.KobeG, leiding.VictorB, leiding.Wout],
};
export default takken;
